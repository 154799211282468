import React, { useState } from "react"
import Layout from "@components/layout"
import { graphql, Link } from "gatsby"
import Seo from "@components/seo"
import Collapse from "react-bootstrap/Collapse"
import { Fade, Slide } from "react-reveal"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
const slugify = require("slugify")

function createSlug(text) {
  return slugify(text, {
    replacement: "-", // replace spaces with replacement
    remove: /[*+~.()'"!:@?]/g, // regex to remove characters
    lower: true, // result in lower case
  })
}

const Blogs = ({ data }) => {
  const [open, setOpen] = useState(false)
  const posts = data.allSanityPost.edges
  const group = data.allSanityPost.group

  return (
    <Layout>
      <Seo
        title="Tech Talk Blog"
        description={data.site.siteMetadata.description}
      />
      <div className="page-headline">
        <div className="container">
          <h6 className="text-white-50 text-uppercase">News &amp; Articles</h6>
          <h1>Tech Talk Blog</h1>
        </div>
      </div>
      <div className="page-content bg-light">
        <div className="container">
          <div className="row justify-content-around">
            <div className="col-md-7 col-lg-8 order-2 order-md-1">
              <div className="row justify-content-center">
                {posts.map((post, idx) => {
                  const image = getImage(
                    post.node.mainImage !== null
                      ? post.node.mainImage.asset.gatsbyImageData
                      : "https://source.unsplash.com/pencil/joshhild/500x500"
                  )
                  const convergimages = convertToBgImage(image)

                  return (
                    <div className="col-12 mb-5" key={idx}>
                      <Link to={`/blog/${post.node.slug.current}`}>
                        <div className="blog-item">
                          <div className="blog-banner-wrapper">
                            <BackgroundImage
                              Tag="div"
                              className="blog-banner"
                              {...convergimages}
                              style={{
                                position: "absolute",
                              }}
                            />
                          </div>
                          <div className="blog-text">
                            <div className="blog-labels text-muted small">
                              <span>
                                <i className="fa fa-clock-o pr-1"></i>
                                {post.node.publishedAt}
                              </span>
                              <span className="pl-3">
                                <i className="fa fa-pencil pr-1"></i>
                                {post.node.authors[0].person.name}
                              </span>
                            </div>
                            <h4 className="text-primary">{post.node.title}</h4>
                            <p className="my-3">{post.node.excerpt}</p>
                            <button className="btn btn-text-only" type="button">
                              Read more
                              <i className="fa fa-arrow-circle-o-right -0"></i>
                            </button>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="col-md-4 col-lg-3 order-1 order-md-2 mb-4">
              <Slide right>
                <div className="side-content">
                  <p className="lead font-weight-bold text-primary">
                    Welcome to the Tech Talk Blog by IT Specialists, where we
                    focus on all things technology and business.{" "}
                  </p>
                  <div className="small">
                    <p>Follow along for more information on:</p>
                    <ul>
                      <li>Growing your business through technology</li>
                      <li>Security updates and potential threats</li>
                      <li>Business and technology tips and tricks</li>
                    </ul>
                  </div>
                </div>
                <div className="side-content">
                  <h6 className="text-uppercase mb-3">Posts by Tag</h6>
                  <Collapse in={open}>
                    <div className="small blogs-tags-list-wrapper">
                      <ul className="blog-tags-list">
                        {group.map((item, i) => (
                          <li key={i}>
                            <Link to={`/blog/${createSlug(item.fieldValue)}`}>
                              {item.fieldValue} ( {item.totalCount} )
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Collapse>
                  <button
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    className="fade-out-collapse-btn"
                  >
                    <span className="d-none">Collapse</span>
                  </button>
                </div>
              </Slide>
              <Fade delay={750}>
                <div className="my-5 blog-subscribe-form-wrapper">
                  <div className="display-4">
                    <i className="fa fa-paper-plane-o"></i>
                  </div>
                  <p className="">
                    Subscribe to our newsletter to get the latest updates.
                  </p>
                  <form
                    action="https://forms.hsforms.com/submissions/v3/public/submit/formsnext/multipart/3843464/afb73d88-a676-41ca-8459-7529fbfd0229"
                    className="subscribe-form"
                    method="POST"
                    target="_blank"
                  >
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="email"
                        id="email"
                        name="email"
                        required
                      />
                      <label htmlFor="email">Email address</label>
                    </div>
                    <div className="form-group text-center">
                      <button
                        className="btn btn-primary btn-block btn-white"
                        type="submit"
                      >
                        Subscribe
                      </button>
                    </div>
                  </form>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Blogs

export const blogQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityPost(sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          id
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                placeholder: BLURRED
                layout: CONSTRAINED
                fit: FILL
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          excerpt
          slug {
            current
          }
          body
          categories {
            title
          }
          authors {
            person {
              name
            }
          }
        }
      }
      group(field: categories___title) {
        fieldValue
        totalCount
      }
    }
  }
`
